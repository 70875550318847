import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { apicall, encryptStr, decryptStr } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import NavBar from "../navBar";

const Login = props => {

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [showLoader, setShowLoader] = useState(false)

    // console.log("llllllllllllllllllllllllllll", decryptStr("U2FsdGVkX1%2FOuaoy76Gs0KcNhPryjrXHfKIzYOq6rcw%3D") );

    useEffect(() => {
        // if userdata exist in local storage redirect inside
        let userData = localStorage.getItem("userData")
        if(userData){
            window.location = "/upload-invoice"
        }
    }, [])

    const handleValidation = async e => {
        if(e){
            e.preventDefault()
        }
        let err = {}
        if(!data.email){
            err["email"] = "Mandatory field"
        }
        if(!data.password){
            err["password"] = "Mandatory field"
        }
        setErrors(err)
        if (!Object.keys(err).length) {
            setShowLoader(true)
            let loginResp = await apicall("/user/login", {email: data.email, password: encryptStr(data.password)})
            if(loginResp.status/1 == 403){
                toastDisplay('Invalid credentials', "info")
            }
            else if (loginResp.status/1 == 200){
                localStorage.setItem("userData", JSON.stringify(loginResp.data))
                window.location = "/upload-invoice"
            }            
            setShowLoader(false)
        }
    }

    const handleValidationForForgotPassword = async e => {
        if(e){
            e.preventDefault()
        }
        let err = {}
        if(!data.email){
            err["email"] = "Mandatory field"
        }
        setErrors(err)
        if (!Object.keys(err).length) {
            setShowLoader(true)
            let apiResponse = await apicall("/user/requestForgotPassword", {email: data.email})        
            setShowLoader(false)
            if(apiResponse.status/1 == 403){
                toastDisplay("Something went wrong", "error")
            }
            else if(apiResponse.status/1 == 200){
                toastDisplay("Password reset link sent on your email id", "success", () => {
                    window.location.reload()
                })
            }
        }
    }

    const handleChange = e => {
        e.persist()
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: "" })
    }


    return (
        <>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
        <NavBar activeComponent={""} />

        <div className="d-flex row">
                <div className="my-5 py-5 align-self-center d-grid justify-content-center">
                    <h5 className="mb-4 text-center">Login</h5>
                    <div className="align-self-center px-5">
                        <div class="mb-3 position-relative">
                            <label for="email" class="form-label">Email Id</label>
                            <input type={"text"} class="form-control" id="email" aria-describedby="email"
                                name="email" value={data.email}
                                onChange={handleChange} />
                            {errors.email ? (
                                <div id="email" class="form-text text-danger">{errors.email}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type={"password"} class="form-control" id="password" aria-describedby="password"
                                name="password" value={data.password}
                                onChange={handleChange}
                            />
                            {/* <i
                                onClick={() => toggleConfirmpwSecureEntry(!confirmpwSecureEntry)}
                                className={`fas ${!confirmpwSecureEntry ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i> */}
                            {errors.password ? (
                                <div id="password" class="form-text text-danger">{errors.password}</div>) : null}
                        </div>
                        <label
                                onClick={handleValidationForForgotPassword}
                                className="cursor text-color1 font-size-14" >{`Forgot password`}</label>
                        <div className="d-flex pt-3">
                            <button onClick={handleValidation} type="submit" class="btn btn-primary w-100">Submit</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Login