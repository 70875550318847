import React, { useState } from "react";

const Header = ({ title }) => {

    return (
        <div >
            <label className="font-size-16 font-wt-600 py-4">{title}</label>
        </div>
    )
}

export default Header