import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { apicall, decryptStr, encryptStr } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import NavBar from "../navBar";

const SetPassword = props => {

    const queryParams = new URLSearchParams(window.location.search)
    const requestId = decryptStr(queryParams.get("req"))

    console.log("requestId======================>", requestId);

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [showLoader, setShowLoader] = useState(false)
    const [pwSecureEntry, togglepwSecureEntry] = useState(true)
    const [confirmpwSecureEntry, toggleConfirmpwSecureEntry] = useState(true)

    const handleChange = e => {
        e.persist()
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: "" })
    }

    useEffect(() => {
        if(requestId && requestId?.split("::")?.[1]){
            let timeDiffInHrs = moment().diff(moment(requestId?.split("::")?.[1], "DD/MM/YYYY HH:MM"), "hours")   
            // console.log("timeDiffInHrs", timeDiffInHrs, requestId?.split("::")?.[1]);   
            if(timeDiffInHrs/1 > 72){
                toastDisplay("Invalid link time", "error", () => {
                    window.location = "/"
                })
            }
        }
        else{
            toastDisplay("Invalid link", "error", () => {
                window.location = "/" 
            })
        }
    }, [requestId])

    const handleValidation = async e => {
        if(e){
            e.preventDefault()
        }
        let err = {}
        if(!data.password){
            err["password"] = "Mandatory field"
        }
        if(!data.confirmPassword){
            err["confirmPassword"] = "Mandatory field"
        }
        if(data.password && data.password.length<8){
            err["password"] = "Minimum password length should be 8 digit"
        }
        else if(data.password && data.confirmPassword && data.password != data.confirmPassword){
            err["confirmPassword"] = "Password & confirm password doesn't match"
        }
        setErrors(err)
        if (!Object.keys(err).length) {
            setShowLoader(true)
            await apicall("/user/resetPassword", {password: encryptStr(data.password), requestId: queryParams.get("req")})
            toastDisplay("Password changed successfully", "success",
            () => {
                window.location = "/login"
            })
            setShowLoader(false)
        }
    }

    return (
        <>
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <NavBar activeComponent={""} />
            <div className="d-flex row">
                <div className="my-5 py-5 align-self-center d-grid justify-content-center">
                    <h5 className="mb-4 text-center">Set Your Password</h5>
                    <div className="align-self-center px-5">
                        <div class="mb-3 position-relative">
                            <label for="password" class="form-label">Password</label>
                            <input type={pwSecureEntry ? "password" : "text"} class="form-control" id="password" aria-describedby="password"
                                name="password" value={data.password}
                                onChange={handleChange} />
                            {/* <i
                                onClick={() => togglepwSecureEntry(!pwSecureEntry)}
                                className={`fas ${!pwSecureEntry ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i> */}
                            {errors.password ? (
                                <div id="lederName" class="form-text text-danger">{errors.password}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="confirmPassword" class="form-label">Confirm Password</label>
                            <input type={confirmpwSecureEntry ? "password" : "text"} class="form-control" id="confirmPassword" aria-describedby="confirmPassword"
                                name="confirmPassword" value={data.confirmPassword}
                                onChange={handleChange}
                            />
                            {/* <i
                                onClick={() => toggleConfirmpwSecureEntry(!confirmpwSecureEntry)}
                                className={`fas ${!confirmpwSecureEntry ? "fa-eye" : 'fa-eye-slash'} input-icon`} id="togglePassword" ></i> */}
                            {errors.confirmPassword ? (
                                <div id="confirmPassword" class="form-text text-danger">{errors.confirmPassword}</div>) : null}
                        </div>
                        <div className="d-flex pt-3">
                            <button onClick={handleValidation} type="submit" class="btn btn-primary w-100">Submit</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SetPassword