import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { platformBackendURL } from "../../constants/config";
import { apicall, companyDesignation } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import ModalPopup from "../modalPopup";
import NavBar from "../navBar";

const OnboardWithUs = props => {

    const refOtp1 = useRef(null);
    const refOtp2 = useRef(null);
    const refOtp3 = useRef(null);
    const refOtp4 = useRef(null);

    const refMobOtp1 = useRef(null);
    const refMobOtp2 = useRef(null);
    const refMobOtp3 = useRef(null);
    const refMobOtp4 = useRef(null);

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [otpPopup, toggleOtpPopup] = useState(false)
    const [isEmailVerified, toggleIsEmailVerified] = useState(false);
    const [isMobVerified, toggleIsMobVerified] = useState(false);
    const [countryData, setcountryData] = useState([]);
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        axios.get(platformBackendURL + "/getallCountry").then((result) => {
            if (result.data.message && result.data.message.length) {
                setcountryData(result.data.message);
            }
        });
    }, []);


    const handleChange = e => {
        e.persist()
        if (e.target.name === "contactNumber" || e.target.name === "phoneCode") {
            toggleIsMobVerified(false)
        }
        if (e.target.name === "email") {
            toggleIsEmailVerified(false)
        }
        if (e.target.name.includes('otp')) {
            let currentIndex = e.target.name.split("p")[1] / 1
            if (e.target.value) {
                if (currentIndex == 1) {
                    refOtp2.current.focus()
                }
                if (currentIndex == 2) {
                    refOtp3.current.focus()
                }
                if (currentIndex == 3) {
                    refOtp4.current.focus()
                }
            }
            else {
                if (currentIndex == 4) {
                    refOtp3.current.focus()
                }
                if (currentIndex == 3) {
                    refOtp2.current.focus()
                }
                if (currentIndex == 2) {
                    refOtp1.current.focus()
                }
            }
        }
        if (e.target.name.includes('phoneotp')) {
            let currentIndex = e.target.name.split("_")[1] / 1
            if (e.target.value) {
                if (currentIndex == 1) {
                    refMobOtp2.current.focus()
                }
                if (currentIndex == 2) {
                    refMobOtp3.current.focus()
                }
                if (currentIndex == 3) {
                    refMobOtp4.current.focus()
                }
            }
            else {
                if (currentIndex == 4) {
                    refMobOtp3.current.focus()
                }
                if (currentIndex == 3) {
                    refMobOtp2.current.focus()
                }
                if (currentIndex == 2) {
                    refMobOtp1.current.focus()
                }
            }
        }
        if (e.target.name === "country") {
            setData({
                ...data, [e.target.name]: e.target.value, phoneCode: countryData.filter(i => {
                    if (i.name === e.target.value) {
                        return i
                    }
                })[0]["phonecode"]
            })
            setErrors({ ...errors, [e.target.name]: "", phoneCode: "" })
            return null
        }
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: "" })
    }

    async function verifyCombinedOTP() {
        setShowLoader(true)
        let err = {}
        let req1 = {
            email: data.email,
            verifyEmail: true,
            otp: data.otp1 + "" + data.otp2 + "" + data.otp3 + "" + data.otp4
        }
        let req2 = {
            contactNo: "+" + data.phoneCode + "" + data.contactNumber,
            verifyMob: true,
            otp: data.phoneotp_1 + "" + data.phoneotp_2 + "" + data.phoneotp_3 + "" + data.phoneotp_4
        }
        let mobileOTPRes, emailOTPRes
        emailOTPRes = await axios.post(platformBackendURL + '/sendVerifyOtp', req1)
        if (!emailOTPRes?.data?.success) {
            err["emailOtp"] = "Email otp is invalid"
        }
        mobileOTPRes = await axios.post(platformBackendURL + '/sendVerifyOtp', req2)
        if (!mobileOTPRes?.data?.success) {
            err["mobOtp"] = "Mobile otp is invalid"
        }
        setShowLoader(false)
        if (emailOTPRes?.data?.success && mobileOTPRes?.data?.success) {
            // toastDisplay("OTP Verification Succesful", "success")
            toggleOtpPopup(false)
            setData({ ...data, otp1: "", otp2: "", otp3: "", otp4: "", phoneotp_1: "", phoneotp_2: "", phoneotp_3: "", phoneotp_4: "" })
            setErrors({})
            if (emailOTPRes) {
                toggleIsEmailVerified(true)
            }
            if (mobileOTPRes) {
                toggleIsMobVerified(true)
            }
        }
        setErrors(err)
    }

    useEffect(() => {
        if(isEmailVerified && isMobVerified){
            handleValidation()
        }
    }, [isEmailVerified, isMobVerified])

    async function sendCombinedOTP() {
        if (!data.email && !data.contactNumber) {
            return null
        }
        setShowLoader(true)
        let reqObj = {
            contactNo: "+" + data.phoneCode + "" + data.contactNumber,
            email: data.email,
            sendOnMob: true,
            sendOnEmail: true
        }
        axios.post(platformBackendURL + "/sendVerifyOtp", reqObj).then((result) => {
            setShowLoader(false)
            if (result?.data?.success) {
                toggleOtpPopup(true)
                toastDisplay("OTP sent successfully", "success")
            }
        }).catch(err => {
            setShowLoader(false)
            toastDisplay(err, 'error')
        });
    }

    const handleValidation = async e => {
        if(e){
            e.preventDefault()
        }
        let err = {}
        let validatorArray = ["lenderName", "contactPerson", "designation", "email", "country", "phoneCode", "contactNumber"]
        for (let index = 0; index < validatorArray.length; index++) {
            const element = validatorArray[index];
            if (!data[element]) {
                err[element] = "Mandatory field"
            }
        }
        if (data.designation === "Other" && !data.otherDesignation) {
            err["otherDesignation"] = "Enter designation"
        }
        setErrors(err)
        if (!Object.keys(err).length) {
            if (isEmailVerified && isMobVerified) {
                setShowLoader(true)
                let resp = await apicall("/user/raiseRecruitmentRequest", { ...data })
                setShowLoader(false)
                if(resp.status/1 == 409){
                    toastDisplay(resp.msg, "info",
                    () => window.location.reload())
                }
                else{
                    toastDisplay(resp.msg, "success", 
                    () => window.location.reload()
                    )
                }
            }
            else {
                sendCombinedOTP()
            }
        }
    }


    return (
        <>{showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ModalPopup
                body={
                    <div>
                        <label className="font-size-15">Enter Email OTP</label>
                        <div className="form-group mb-4 w-75 d-flex flex-row justify-content-between my-3 mx-4"
                            style={{ height: "2.6rem" }}
                        >
                            <input type="text" id="otp1" ref={refOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp1" value={data.otp1} onChange={handleChange} />
                            <input type="text" id="otp2" ref={refOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp2" value={data.otp2} onChange={handleChange} />
                            <input type="text" id="otp3" ref={refOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp3" value={data.otp3} onChange={handleChange} />
                            <input type="text" id="otp4" ref={refOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.otp ? '' : ' border-danger')} name="otp4" value={data.otp4} onChange={handleChange} />
                        </div>
                        {errors.emailOtp && <label className="w-90 text-danger font-size-14 mb-3">{errors.emailOtp}</label>}
                        <label className="font-size-15">Enter Mobile OTP</label>
                        <div className="form-group mb-4 w-75 d-flex flex-row justify-content-between my-3 mx-4"
                            style={{ height: "2.6rem" }}
                        >
                            <input type="text" id="otp1" ref={refMobOtp1} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_1" value={data.phoneotp_1} onChange={handleChange} />
                            <input type="text" id="otp2" ref={refMobOtp2} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_2" value={data.phoneotp_2} onChange={handleChange} />
                            <input type="text" id="otp3" ref={refMobOtp3} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_3" value={data.phoneotp_3} onChange={handleChange} />
                            <input type="text" id="otp4" ref={refMobOtp4} maxLength={1} className={" form-control w-15 h-100" + (!errors.phoneotp ? '' : ' border-danger')} name="phoneotp_4" value={data.phoneotp_4} onChange={handleChange} />
                        </div>
                        {errors.mobOtp && <label className="w-90 text-danger font-size-14 mb-3">{errors.mobOtp}</label>}
                        <label className="cursor w-90 light-font font-size-14" >{`An OTP has been sent on your mentioned Email Id & Mobile No`}</label>
                        <div className="row w-100 p-4">
                            <label
                                onClick={() => sendCombinedOTP()}
                                className="cursor w-50 text-color1 font-size-14" >{`Resend OTP`}</label>
                            <label
                                onClick={() => toggleOtpPopup(false)}
                                className="cursor w-50 text-color1 font-size-14" >{`Change Email Id/Mobile Number`}</label>
                        </div>

                    </div>
                }

                btns={[
                    { name: "Verify OTP", class: 'btn btn-primary', onClick: () => verifyCombinedOTP() },
                ]}
                title={"Verification"} show={otpPopup} onClose={() => toggleOtpPopup(false)} />
            <NavBar activeComponent={"recruit"} />
            <div className="d-flex row">
                <div className="my-5 py-5 align-self-center d-grid justify-content-center">
                    <h5 className="mb-4 text-center">Create a Onboarding Request</h5>
                    <div className="align-self-center px-5">
                        <div class="mb-3">
                            <label for="lenderName" class="form-label">Lender Name</label>
                            <input type="text" class="form-control" id="lenderName" aria-describedby="lederName"
                                name="lenderName" value={data.lenderName}
                                onChange={handleChange} />
                            {errors.lenderName ? (
                                <div id="lederName" class="form-text text-danger">{errors.lenderName}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="contactPerson" class="form-label">Contact Person Name</label>
                            <input type="text" class="form-control" id="contactPerson" aria-describedby="contactPerson"
                                name="contactPerson" value={data.contactPerson}
                                onChange={handleChange}
                            />
                            {errors.contactPerson ? (
                                <div id="contactPerson" class="form-text text-danger">{errors.contactPerson}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="lenderName" class="form-label">Designation</label>
                            <select name={"designation"} className="form-select" id="floatingSelectGrid" aria-label="Floating label select example"
                                value={data.designation} onChange={handleChange}
                            >
                                <option value="" selected>Select Designation</option>
                                {companyDesignation.map((item, index) => {
                                    return (
                                        <option value={item.name}>{item.name}</option>
                                    )
                                })}
                            </select>
                            {errors.designation ? (
                                <div id="designation" class="form-text text-danger">{errors.designation}</div>) : null}
                        </div>
                        {data.designation === "Other" ? (
                            <div class="mb-3">
                                <label for="otherDesignation" class="form-label" >Enter Designation</label>
                                <input type="text" class="form-control" id="otherDesignation" aria-describedby="otherDesignation"
                                    name="otherDesignation" value={data.otherDesignation}
                                    onChange={handleChange} />
                                {errors.otherDesignation ? (
                                    <div id="otherDesignation" class="form-text text-danger">{errors.otherDesignation}</div>) : null}
                            </div>
                        ) : null}
                        <div class="mb-3">
                            <label for="email" class="form-label">Email address</label>
                            <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                                name="email" value={data.email} onChange={handleChange}
                            />
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                            {errors.email ? (
                                <div id="emailHelp" class="form-text text-danger">{errors.email}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="lenderName" class="form-label">Country</label>
                            <select name={"country"} className="form-select" id="country" aria-label="Floating label select example"
                                value={data.country} onChange={handleChange}
                            >
                                <option value={""} selected>Select Country</option>
                                {countryData.map((item, index) => {
                                    return (
                                        <option value={item.name}>{item.name}</option>
                                    )
                                })}
                            </select>
                            {errors.country ? (
                                <div id="country" class="form-text text-danger">{errors.country}</div>) : null}
                        </div>
                        <div class="mb-3">
                            <label for="" class="form-label">Contact Number</label>
                            <div className="d-flex">
                                <div className="w-35">
                                    <select name={"phoneCode"} className="form-select" id="phoneCode" aria-label="Floating label select example"
                                        value={data.phoneCode} onChange={handleChange}
                                    >
                                        <option value={""} selected>+</option>
                                        {countryData.map((item, index) => {
                                            return (
                                                <option value={item.phonecode}>{"+ " + item.phonecode}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="w-65">
                                    <input type="number" class="form-control" id="contactNumber"
                                        name="contactNumber" value={data.contactNumber} onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {(errors.contactNumber || errors.phoneCode) ? (
                                <div id="country" class="form-text text-danger">{errors.contactNumber || errors.phoneCode}</div>) : null}
                        </div>
                        <div className="d-flex pt-3">
                            <button onClick={handleValidation} type="submit" class="btn btn-primary w-100">Submit</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OnboardWithUs;