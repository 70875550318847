import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { platformBackendURL } from "../../constants/config";
import { apicall, convertImageToPdf } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import Header from "../header";
import SideBar from "../sideBar";
import Papa from "papaparse";
import moment from "moment";

const UploadInvoices = ({ activeComponent }) => {

    let columns = [{ name: "Invoice Number", alt: "invoiceNo" }, { name: "Invoice Currency", alt: "invoiceCurrency" },
    { name: "Invoice Amount", alt: "invoiceAmount" }, { name: "PO Number", alt: "poNo" }, { name: "BL Number", alt: 'blNo' },
    { name: "BL Issue Date", alt: "blIssueDate" },
    { name: "Exporter Name", alt: "exporterName" }, { name: "Buyer Name", alt: "buyerName" }, { name: "Shipment Origin Country", alt: "shipmentOriginCountry" },
    { name: "Shipment Origin City", alt: "shipmentOriginCity" }, { name: "Shipment Origin Postal Code", alt: "shipmentOriginPostalCode" }, { name: "Shipment Origin Address", alt: "shipmentOriginAddress" },
    { name: "Shipment Destination Country", alt: "shipmentDestinationCountry" }, { name: "Shipment Destination City", alt: "shipmentDestinationCity" },
    { name: "Shipment Destination Postal Code", alt: "shipmentDestinationPostalCode" }, { name: "Shipment Destination Address", alt: "shipmentDestinationAddress" },
    { name: "Disbursement schedule ", dynamic: true }
    ]


    const [data, setData] = useState({ invoiceCurrency: 'USD' })
    const [errors, setErrors] = useState({})
    const [countryData, setcountryData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [disbursementArr, setDisbursementArr] = useState([{ disbursedCurrency: "USD" }])
    const [showLoader, setShowLoader] = useState(false)
    const [csvFile, setCsvFile] = useState({})
    const [checkedColumns, setCheckedColumns] = useState({})
    const [uploadStatus, setUploadStatus] = useState({})
    const [isSelectAll, toggleSelectAll] = useState(true)

    useEffect(() => {
        if(data?.invoiceDocument?.name){
            analyzeInvoiceDocument()
        }
    }, [data.invoiceDocument])

    async function analyzeInvoiceDocument() {
        let formData = new FormData()
        formData.append("document", data.invoiceDocument)
        setShowLoader(true)
        toastDisplay("Analyzing document kindly wait", "info")
        try {
            let resp = await apicall('/invoice/analyzeInvoice', formData, true)
            // console.log("rrrrrrrrrrrrrrrrrrrrrrrrrr", resp);
            setData({
                ...data,
                invoiceNo: resp?.data?.invoice_number || "",

                exporterName: resp?.data?.supplier_name || "",
                shipmentOriginAddress: resp?.data?.supplier_address || "",
                shipmentOriginCity: resp?.data?.supplier_address_components?.city || "",
                shipmentOriginPostalCode: resp?.data?.supplier_address_components?.postalCode || "",
                shipmentOriginCountry: resp?.data?.supplier_address_components?.countryName || "",

                buyerName: resp?.data?.customer_name || "",
                shipmentDestinationAddress: resp?.data?.customer_address || "",
                shipmentDestinationCity: resp?.data?.customer_address_components?.city || "",
                shipmentDestinationPostalCode: resp?.data?.customer_address_components?.postalCode || "",
                shipmentDestinationCountry: resp?.data?.customer_address_components?.countryName || "",
            })
        } catch (error) {   
        }
        setShowLoader(false)   
    }

    useEffect(() => {
        if (csvFile?.rows?.length) {
            let tempCheckedFields = {}
            for (let m = 0; m < csvFile?.rows.length; m++) {
                const i = csvFile?.rows[m];
                let isWholeRowValid = true
                for (let index = 0; index < columns.length; index++) {
                    const element = columns[index];
                    let validatorResp = validateField(i[index], index);
                    if (!validatorResp.success) {
                        isWholeRowValid = false
                    }
                }
                if (isWholeRowValid) {
                    tempCheckedFields["col" + m] = isSelectAll
                }
            }
            setCheckedColumns(tempCheckedFields)
        }
    }, [csvFile.rows, isSelectAll])

    useEffect(() => {
        setShowLoader(true)
        axios.get(platformBackendURL + "/getcurrencylist").then((result) => {
            setShowLoader(false)
            if (result.data.message.length) {
                setCurrencyData(result.data.message);
            }
        }).catch((e) => {
            console.log("error in getcurrencylist", e);
            setShowLoader(false)
        });

        axios.get(platformBackendURL + "/getallCountry").then((result) => {
            setShowLoader(false)
            if (result.data.message.length) {
                setcountryData(result.data.message);
            }
        }).catch((e) => {
            console.log("error in getallCountry", e);
            setShowLoader(false)
        });
    }, [])

    const handleCSV = (event) => {
        // event.persist();
        if (!event.target.files.length) {
            return null
        }
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeee", event.target.files[0]);
        setErrors({ ...errors, "bulk_upld_error": "" });
        if (!event.target.files[0]['name'].includes("csv")) {
            setErrors({ ...errors, "invoiceCSVFile": "Please select csv file" });
            setCsvFile({ ...csvFile, name: event.target.files[0]['name'], rows: [] })
            return
        }
        Papa.parse(event.target.files[0], {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
                let rows = results.data;
                // console.log("processed csv rows", rows);
                if (rows && rows.length > 1) {
                    rows.shift()
                    setCsvFile({ ...csvFile, rows, name: event.target.files[0]['name'] })
                    setErrors({ ...errors, "invoiceCSVFile": "" });
                }
                else {
                    setErrors({ ...errors, "invoiceCSVFile": "Selected file is empty" });
                    setCsvFile({ ...csvFile, name: event.target.files[0]['name'], rows: [] })
                }
            }
        });
    }

    const handleFiles = (event) => {
        event.persist();
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
            setErrors({ ...errors, invoiceDocument: "Files with pdf, png & jpeg extension are allowed" })
            return
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
            let fileObj = event.target.files[0]
            let fileDataUrl = e.target.result
            if (!file_type.includes("pdf")) {
                let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
                fileObj = response["file"]
                fileDataUrl = response['dataUrl']
            }
            setData({ ...data, [event.target.name]: fileObj })
            setErrors({ ...errors, [event.target.name]: "" });
        }
    };

    const handleChange = e => {
        e.persist()
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: "" })
    }

    const handleDisbursementArray = (e, index) => {
        e.persist()
        let temp = disbursementArr
        temp[index][e.target.name] = e.target.value
        setDisbursementArr([...temp])
    }

    async function handleValidation(e) {
        e.preventDefault()
        let validate = ["invoiceNo", "invoiceCurrency", "invoiceAmount", "poNo", "blNo", "blIssueDate", "exporterName", "buyerName", "shipmentOriginCountry", "shipmentDestinationCountry", "shipmentOriginCity",
            "shipmentOriginAddress", "shipmentDestinationCity", "shipmentOriginPostalCode", "shipmentDestinationPostalCode", "shipmentDestinationAddress"]
        let err = {}
        for (let index = 0; index < validate.length; index++) {
            const element = validate[index];
            if (!data[element]) {
                err[element] = "Mandatory field"
            }
        }
        if (!Object.keys(err).length) {
            let disbursements = []
            for (let index = 0; index < disbursementArr.length; index++) {
                const element = disbursementArr[index];
                if (element.disbursedDate && element.disbursedCurrency && element.disbursedAmt) {
                    disbursements.push(element)
                }
            }
            setShowLoader(true)
            let formData = new FormData()
            // if (data.blNo) {
                formData.append('blNo', data.blNo)
                formData.append('blIssueDate', data.blIssueDate)
            // }
            formData.append('buyerName', data.buyerName)
            if (disbursements.length) {
                formData.append("disbursements", JSON.stringify(disbursements))
            }
            formData.append('exporterName', data.exporterName)
            formData.append('invoiceAmount', data.invoiceAmount)
            formData.append('invoiceCurrency', data.invoiceCurrency)
            if (data?.invoiceDocument?.name) {
                formData.append('invoiceDocument', data.invoiceDocument)
            }
            formData.append('invoiceNo', data.invoiceNo)
            formData.append('poNo', data.poNo)
            formData.append('shipmentDestinationAddress', data.shipmentDestinationAddress)
            formData.append('shipmentDestinationCity', data.shipmentDestinationCity)
            formData.append('shipmentDestinationCountry', data.shipmentDestinationCountry)
            formData.append('shipmentDestinationPostalCode', data.shipmentDestinationPostalCode)
            formData.append('shipmentOriginAddress', data.shipmentOriginAddress)
            formData.append('shipmentOriginCity', data.shipmentOriginCity)
            formData.append('shipmentOriginCountry', data.shipmentOriginCountry)
            formData.append('shipmentOriginPostalCode', data.shipmentOriginPostalCode)
            let resp = await apicall('/invoice/createInvoiceFingerprint', formData, true)
            if (resp.status / 1 == 200) {
                toastDisplay("Data updated successfully", "success",
                    () => window.location.reload())
            }
            if (resp.status / 1 == 409) {
                toastDisplay("Invoice data already exist", "info")
            }
            setShowLoader(false)
        }
        setErrors(err)
    }

    const validateField = (value, col_index) => {
        value = value?.trim() || "";
        let mandatoryFields = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]
        if(mandatoryFields.includes(col_index)){
            if(!value){
                return { "msg": "Required", "success": false }
            }
        }
        if(col_index == 1){
            let isValidCurrencyCode = currencyData.filter(i => {
                if(i.code === value){
                    return i
                }
            })
            if(!isValidCurrencyCode?.length){
                return {msg: "Invalid Currency Code", "success" : false}
            }
        }
        if(col_index == 2){
            if (isNaN(value / 1)) {
                return { "msg": "Invalid No", "success": false }
            }
        }
        if(col_index == 5){
            if (value && moment(value).format("YYYY-MM-DD") != value) {
                return { "msg": "Invalid BL Date Format", "success": false }
            }
        }
        if(col_index == 16 && value){
            let allDisbursementData = value.split(",")
            for (let index = 0; index < allDisbursementData.length; index++) {
                let element = allDisbursementData[index];
                element = element.trim()
                let tempValue = element.split("/")
                if(!tempValue[0]){
                    return { "msg": "Date Required", "success": false }
                }
                if(!tempValue[1]){
                    return { "msg": "Currency Code Required", "success": false }
                }
                if(!tempValue[2]){
                    return { "msg": "Amount Required", "success": false }
                }
                if(tempValue[0] && moment(tempValue[0]).format("YYYY-MM-DD") != tempValue[0]){
                    return { "msg": "Invalid Date Format", "success": false }
                }
                if(tempValue[1] && !currencyData.filter(i => {
                    if(i.code === tempValue[1]){
                        return i
                    }
                })?.length ){
                    return { "msg": "Invalid Currency Code", "success": false }
                }
                if(tempValue[2] && isNaN(tempValue[2]/1)){
                    return { "msg": "Invalid Amount", "success": false }
                }
            }
        }
        return { "msg": "", "success": true }
    }

    async function handleCSVFileSubmission() {
        let temp = Object.values(checkedColumns)
        if(!temp.includes(true)){
            toastDisplay("Kindly select atleast 1 invoice to upload", "info")
        }
        else{
            setShowLoader(true)
            let tempUploadStatus = {}
            for (let index = 0; index < csvFile.rows.length; index++) {
                let element = csvFile.rows[index];
                if(checkedColumns["col"+index]){
                    let reqBody = {}
                    if (element[4]) {
                        reqBody['blNo'] = element[4].trim()
                        reqBody['blIssueDate'] = element[5].trim()
                    }
                    reqBody['buyerName'] = element[7].trim()
                    let disbursements = []
                    if (element[16].trim()) {
                        let tempDisbArr = element[16].trim().split(",")
                        for (let index = 0; index < tempDisbArr.length; index++) {
                            let disbEntry = tempDisbArr[index];
                            disbEntry = disbEntry.split("/")
                            disbursements.push({
                                disbursedDate: disbEntry[0],
                                disbursedCurrency: disbEntry[1],
                                disbursedAmt: disbEntry[2]
                            })
                        }
                    }
                    if(disbursements.length){                    
                        reqBody["disbursements"] = JSON.stringify(disbursements)
                    }
                    reqBody["exporterName"] = element[6].trim()
                    reqBody['invoiceAmount'] = element[2].trim()
                    reqBody['invoiceCurrency'] = element[1].trim()
                    reqBody['invoiceNo'] = element[0].trim()
                    reqBody['poNo'] = element[3].trim()
                    reqBody['shipmentDestinationAddress'] = element[15].trim()
                    reqBody['shipmentDestinationCity'] = element[13].trim()
                    reqBody['shipmentDestinationCountry'] = element[12].trim()
                    reqBody['shipmentDestinationPostalCode'] = element[14].trim()
                    reqBody['shipmentOriginAddress'] = element[11].trim()
                    reqBody['shipmentOriginCity'] = element[9].trim()
                    reqBody['shipmentOriginCountry'] = element[8].trim()
                    reqBody['shipmentOriginPostalCode'] = element[10].trim()
                    // console.log("rrrrrrrrrrrrrrrrrrrrrrrrr", reqBody);
                    let resp = await apicall('/invoice/createInvoiceFingerprint', reqBody)
                    if (resp.status / 1 == 200) {
                        tempUploadStatus["col"+index] = true                     
                    }
                    if (resp.status / 1 == 409) {
                        tempUploadStatus["col"+index] = false
                    }
                }
            }
            setShowLoader(false)
            setUploadStatus(tempUploadStatus)
        }
    }

    return (<>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
        <div class="container-fluid">
            <div class="row flex-nowrap">
                <SideBar state={"invoice"} url={'upload-invoice'} />
                <div class="col">
                    <Header title={"Upload Invoice"} />
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Single Invoice
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        {/* <label className="my-3 text-dark font-size-16 font-wt-600">Fill Invoice Information</label> */}

                                        <div className="row mb-3">
                                            <div class="col-5">
                                                <label for="invoiceDocument" class="form-label">Upload Invoice</label>
                                                <input type="file" class="form-control" id="invoiceDocument" aria-describedby="invoiceDocument"
                                                    name="invoiceDocument"
                                                    // value={data.invoiceDocument}
                                                    onChange={handleFiles} />
                                                {/* <div id="emailHelp" class="form-text">Upload invoice document to fetch details automatically.</div> */}
                                                {errors.invoiceDocument ? (
                                                    <div id="invoiceDocument" class="form-text text-danger">{errors.invoiceDocument}</div>) : null}
                                            </div>
                                            <div
                                                // style={{paddingTop: '2.3rem', height: '5.3rem'}}
                                                class="col-5 align-self-center pt-4">
                                                <div id="emailHelp" class="form-text">Upload invoice document to fetch details automatically.</div>
                                            </div>
                                        </div>

                                        <div className="col-5">

                                            <div class="mb-3">
                                                <label for="invoiceNo" class="form-label">Invoice Number *</label>
                                                <input type="text" class="form-control" id="invoiceNo" aria-describedby="invoiceNo"
                                                    name="invoiceNo" value={data.invoiceNo}
                                                    onChange={handleChange} />
                                                {errors.invoiceNo ? (
                                                    <div id="invoiceNo" class="form-text text-danger">{errors.invoiceNo}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="poNo" class="form-label">PO Number *</label>
                                                <input type="text" class="form-control" id="poNo" aria-describedby="poNo"
                                                    name="poNo" value={data.poNo}
                                                    onChange={handleChange} />
                                                {errors.poNo ? (
                                                    <div id="poNo" class="form-text text-danger">{errors.poNo}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="blIssueDate" class="form-label">BL Issue Date *</label>
                                                <input type="date" class="form-control" id="blIssueDate" aria-describedby="blIssueDate"
                                                    name="blIssueDate" value={data.blIssueDate}
                                                    onChange={handleChange} />
                                                {errors.blIssueDate ? (
                                                    <div id="blIssueDate" class="form-text text-danger">{errors.blIssueDate}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="buyerName" class="form-label">Buyer Name *</label>
                                                <input type="text" class="form-control" id="buyerName" aria-describedby="buyerName"
                                                    name="buyerName" value={data.buyerName}
                                                    onChange={handleChange} />
                                                {errors.buyerName ? (
                                                    <div id="buyerName" class="form-text text-danger">{errors.buyerName}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="shipmentOriginCity" class="form-label">Shipment Origin City *</label>
                                                <input type="text" class="form-control" id="shipmentOriginCity" aria-describedby="shipmentOriginCity"
                                                    name="shipmentOriginCity" value={data.shipmentOriginCity}
                                                    onChange={handleChange} />
                                                {errors.shipmentOriginCity ? (
                                                    <div id="shipmentOriginCity" class="form-text text-danger">{errors.shipmentOriginCity}</div>) : null}
                                            </div>                                           

                                            <div class="mb-3">
                                                <label for="shipmentOriginAddress" class="form-label">Shipment Origin Address *</label>
                                                <input type="text" class="form-control" id="shipmentOriginAddress" aria-describedby="shipmentOriginAddress"
                                                    name="shipmentOriginAddress" value={data.shipmentOriginAddress}
                                                    onChange={handleChange} />
                                                {errors.shipmentOriginAddress ? (
                                                    <div id="shipmentOriginAddress" class="form-text text-danger">{errors.shipmentOriginAddress}</div>) : null}
                                            </div>
                                            

                                            <div class="mb-3">
                                                <label for="shipmentDestinationCity" class="form-label">Shipment Destination City *</label>
                                                <input type="text" class="form-control" id="shipmentDestinationCity" aria-describedby="shipmentDestinationCity"
                                                    name="shipmentDestinationCity" value={data.shipmentDestinationCity}
                                                    onChange={handleChange} />
                                                {errors.shipmentDestinationCity ? (
                                                    <div id="shipmentDestinationCity" class="form-text text-danger">{errors.shipmentDestinationCity}</div>) : null}
                                            </div>   

                                            <div class="mb-3">
                                                <label for="shipmentDestinationAddress" class="form-label">Shipment Destination Address *</label>
                                                <input type="text" class="form-control" id="shipmentDestinationAddress" aria-describedby="shipmentDestinationAddress"
                                                    name="shipmentDestinationAddress" value={data.shipmentDestinationAddress}
                                                    onChange={handleChange} />
                                                {errors.shipmentDestinationAddress ? (
                                                    <div id="shipmentDestinationAddress" class="form-text text-danger">{errors.shipmentDestinationAddress}</div>) : null}
                                            </div>                                         


                                            {disbursementArr.map((item, index) => {
                                                if (index % 2 != 0) {
                                                    return (
                                                        <div class="mb-3">
                                                            <label for="" class="form-label">{`Disbursement Schedule Details - ${index + 1} (optional)`}</label>
                                                            <div className="d-flex">
                                                                <div className="w-40">
                                                                    <input type="date" class="form-control" id=""
                                                                        name={`disbursedDate`} value={item.disbursedDate} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="w-20">
                                                                    <select name={"disbursedCurrency"} className="form-select" id="disbursedCurrency" aria-label="Floating label select example"
                                                                        value={item.disbursedCurrency} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    >
                                                                        <option value={""} selected>Select</option>
                                                                        {currencyData.map((item, index) => {
                                                                            return (
                                                                                <option value={item.code}>{item.code}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="w-40">
                                                                    <input placeholder="amount" type="number" class="form-control" id="disbursedAmt"
                                                                        name={'disbursedAmt'} value={item.disbursedAmt} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}                                            

                                        </div>

                                        <div className="col-5">
                                            <div class="mb-3">
                                                <label for="" class="form-label">Invoice Amount *</label>
                                                <div className="d-flex">
                                                    <div className="w-35">
                                                        <select name={"invoiceCurrency"} className="form-select" id="invoiceCurrency" aria-label="Floating label select example"
                                                            value={data.invoiceCurrency} onChange={handleChange}
                                                        >
                                                            <option value={""} selected>Select</option>
                                                            {currencyData.map((item, index) => {
                                                                return (
                                                                    <option value={item.code}>{item.code}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="w-65">
                                                        <input type="number" class="form-control" id="invoiceAmount"
                                                            name="invoiceAmount" value={data.invoiceAmount} onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                {(errors.invoiceAmount || errors.invoiceCurrency) ? (
                                                    <div id="country" class="form-text text-danger">{errors.invoiceAmount || errors.invoiceCurrency}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="blNo" class="form-label">BL Number *</label>
                                                <input type="text" class="form-control" id="blNo" aria-describedby="blNo"
                                                    name="blNo" value={data.blNo}
                                                    onChange={handleChange} />
                                                {errors.blNo ? (
                                                    <div id="blNo" class="form-text text-danger">{errors.blNo}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="exporterName" class="form-label">Exporter Name *</label>
                                                <input type="text" class="form-control" id="exporterName" aria-describedby="exporterName"
                                                    name="exporterName" value={data.exporterName}
                                                    onChange={handleChange} />
                                                {errors.exporterName ? (
                                                    <div id="exporterName" class="form-text text-danger">{errors.exporterName}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="shipmentOriginCountry" class="form-label">Shipment Origin Country *</label>
                                                <select name={"shipmentOriginCountry"} className="form-select" id="shipmentOriginCountry" aria-label="Floating label select example"
                                                    value={data.shipmentOriginCountry} onChange={handleChange}
                                                >
                                                    <option value={""} selected>Select Country</option>
                                                    {countryData.map((item, index) => {
                                                        return (
                                                            <option value={item.name}>{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {errors.shipmentOriginCountry ? (
                                                    <div id="shipmentOriginCountry" class="form-text text-danger">{errors.shipmentOriginCountry}</div>) : null}
                                            </div>                                            
                                            

                                            <div class="mb-3">
                                                <label for="shipmentOriginPostalCode" class="form-label">Shipment Origin Postal Code *</label>
                                                <input type="text" class="form-control" id="shipmentOriginPostalCode" aria-describedby="shipmentOriginPostalCode"
                                                    name="shipmentOriginPostalCode" value={data.shipmentOriginPostalCode}
                                                    onChange={handleChange} />
                                                {errors.shipmentOriginPostalCode ? (
                                                    <div id="shipmentOriginPostalCode" class="form-text text-danger">{errors.shipmentOriginPostalCode}</div>) : null}
                                            </div>                                           



                                            <div class="mb-3">
                                                <label for="shipmentDestinationCountry" class="form-label">Shipment Destination Country *</label>
                                                <select name={"shipmentDestinationCountry"} className="form-select" id="shipmentDestinationCountry" aria-label="Floating label select example"
                                                    value={data.shipmentDestinationCountry} onChange={handleChange}
                                                >
                                                    <option value={""} selected>Select Country</option>
                                                    {countryData.map((item, index) => {
                                                        return (
                                                            <option value={item.name}>{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                {errors.shipmentDestinationCountry ? (
                                                    <div id="shipmentDestinationCountry" class="form-text text-danger">{errors.shipmentDestinationCountry}</div>) : null}
                                            </div>

                                            <div class="mb-3">
                                                <label for="shipmentDestinationPostalCode" class="form-label">Shipment Destination Postal Code *</label>
                                                <input type="text" class="form-control" id="shipmentDestinationPostalCode" aria-describedby="shipmentDestinationPostalCode"
                                                    name="shipmentDestinationPostalCode" value={data.shipmentDestinationPostalCode}
                                                    onChange={handleChange} />
                                                {errors.shipmentDestinationPostalCode ? (
                                                    <div id="shipmentDestinationPostalCode" class="form-text text-danger">{errors.shipmentDestinationPostalCode}</div>) : null}
                                            </div>                                            

                                            
                                            {disbursementArr.map((item, index) => {
                                                if (index % 2 == 0) {
                                                    return (
                                                        <div class="mb-3">
                                                            <label for="" class="form-label">{`Disbursement Schedule Details - ${index + 1} (optional)`}</label>
                                                            <div className="d-flex">
                                                                <div className="w-40">
                                                                    <input type="date" class="form-control" id=""
                                                                        name={`disbursedDate`} value={item.disbursedDate} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="w-20">
                                                                    <select name={"disbursedCurrency"} className="form-select" id="disbursedCurrency" aria-label="Floating label select example"
                                                                        value={item.disbursedCurrency} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    >
                                                                        <option value={""} selected>Select</option>
                                                                        {currencyData.map((item, index) => {
                                                                            return (
                                                                                <option value={item.code}>{item.code}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="w-40">
                                                                    <input placeholder="amount" type="number" class="form-control" id="disbursedAmt"
                                                                        name={'disbursedAmt'} value={item.disbursedAmt} onChange={(e) => handleDisbursementArray(e, index)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                            <div
                                                onClick={() => {
                                                    setDisbursementArr(disbursementArr.concat([{ disbursedCurrency: 'USD' }]))
                                                }}
                                                className="cursor">
                                                <label className="text-color1 font-size-14 font-wt-600 cursor">Add More Disbursement Schedule+</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="w-25 pt-4 pb-3">
                                        <button onClick={handleValidation} type="submit" class="btn btn-primary w-100">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header " id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Bulk Invoice
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div className="row">
                                        <div className="col-5 d-flex align-items-center">
                                        <a className="text-dark cursor-pointer" href={`${window.location.origin}/assets/docs/invoiceDetailsTemplate.csv`}
                                                    target="_blank" download>
                                            <label
                                                className="cursor">                                                
                                                    <img className='' src={`${window.location.origin}/assets/images/csv.jpeg`} alt="" width="25" height="25"></img>
                                            </label>
                                            <label className='font-size-14 font-wt-600 text-decoration-underline mx-2 cursor'>
                                                {"Download Template"}
                                            </label>
                                            </a>
                                        </div>
                                        <div className="col-5">
                                            <div class="mb-3">
                                                <label for="invoiceCSVFile" class="form-label font-size-14 font-wt-500">Upload CSV file</label>
                                                <input type="file" class="form-control" id="invoiceCSVFile" aria-describedby="invoiceCSVFile"
                                                    name="invoiceCSVFile"
                                                    onChange={handleCSV} />
                                                {errors.invoiceCSVFile ? (
                                                    <div id="invoiceCSVFile" class="form-text text-danger">{errors.invoiceCSVFile}</div>) : null}
                                            </div>
                                        </div>
                                    </div>

                                    {csvFile?.rows?.length ? (
                                        <div className=''>
                                            <h6 className='text-danger font-size-13 font-wt-600'>Note* - Invoice number highlighted in red contains data error</h6>

                                            <div className="row"
                                            style={{marginLeft: "0.3rem", marginTop: '2rem'}}
                                            >
                                                <div className="col-5 d-flex align-items-center">
                                                <input name={"col"}
                                                    style={{
                                                        width: "1.3rem",
                                                        height: "1.3rem",
                                                        marginRight: "1rem"
                                                    }}
                                                    onClick={() => {
                                                        toggleSelectAll(!isSelectAll)
                                                    }}
                                                    class="" type="checkbox" checked={isSelectAll ? "true" : ""} />                                                    
                                                    <label className="font-size-14 font-wt-600 align-self-center pl-3">{`${!isSelectAll ? 'Select All' : "Deselect All"}`}</label>
                                                </div>
                                            </div>

                                            <div class="accordion my-4" id="accordionForBulk">
                                                {csvFile.rows.map((i, j) => {
                                                    let isWholeRowValid = true
                                                    for (let index = 0; index < columns.length; index++) {
                                                        const element = columns[index];
                                                        let validatorResp = validateField(i[index], index);
                                                        if(!validatorResp.success){
                                                            isWholeRowValid = false
                                                        }
                                                    }
                                                    // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjj", "col"+j, checkedColumns);
                                                    return (
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header position-relative" id={`heading${j}`}>

                                                            <div className="position-absolute"
                                                                    style={{
                                                                        top: "0.4rem",
                                                                        zIndex: 10,
                                                                        left: "1rem"
                                                                    }}
                                                                >
                                                                    <input name={"col"+i}
                                                                    style={{
                                                                        width: "1.3rem",
                                                                        height: "1.3rem"
                                                                    }}
                                                                    onClick={() => {
                                                                        if(isWholeRowValid){
                                                                            setCheckedColumns({...checkedColumns, ["col"+j]: !checkedColumns["col"+j] })
                                                                        }
                                                                        else{
                                                                            toastDisplay("Kindly fix given errors", "info")
                                                                        }
                                                                    }}
                                                                    class="" type="checkbox" checked={checkedColumns["col"+j] ? "true" : ""}/>
                                                                </div>

                                                                {checkedColumns["col"+j] && Object.keys(uploadStatus).length ? (
                                                                    <div className="position-absolute"
                                                                    style={{
                                                                        top: "0rem",
                                                                        zIndex: 10,
                                                                        right: "5rem"
                                                                    }}
                                                                >
                                                                    <label className={`font-size-12 ${uploadStatus["col" + j] ? ' text-success ' : ' text-danger '} `}>{uploadStatus["col" + j] ? "Uploaded Successfully" : 'Upload Failed - Duplicate Entry'}</label>                                                                    
                                                                </div>
                                                                ) : null}
                                                                

                                                                <button
                                                                style={{paddingLeft: '3rem'}}
                                                                className={`accordion-button collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${j}`} aria-expanded="false" aria-controls={`collapse${j}`}>
                                                                    <label className={` ${!isWholeRowValid ? ' text-danger ' : ' text-dark '} font-wt-600 font-size-14`}>{i[0]}</label>
                                                                </button>
                                                                
                                                            </h2>
                                                            <div id={`collapse${j}`} class="accordion-collapse collapse" aria-labelledby={`heading${j}`} data-bs-parent="#accordionForBulk">
                                                                <div class="accordion-body">
                                                                    <div className="row d-flex">
                                                                        {columns.map((l, m) => {
                                                                            let res = validateField(i[m], m);
                                                                            if (res.success) {
                                                                                return (
                                                                                    <div className="w-25 my-2   ">
                                                                                        <div>
                                                                                            <label className="font-size-14 ">{i[m] || "NA"}</label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="font-size-12 font-wt-500 ">{l.name}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <div className="w-25 my-2   ">
                                                                                        <div>
                                                                                            <label className="font-size-14 text-danger">{res.msg}</label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="font-size-14 text-danger">{i[m] || "NA"}</label>
                                                                                        </div>
                                                                                        <div>
                                                                                            <label className="font-size-12 font-wt-500 text-danger">{l.name}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                        <div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className="w-25 pt-2">
                                                <button onClick={handleCSVFileSubmission} type="submit" class="btn btn-primary w-100">Submit</button>
                                            </div>
                                        </div>
                                    ) : null}



                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>)
}

export default UploadInvoices;