import logo from './logo.svg';
import './App.css';
import { useRoutes } from "react-router-dom";
import HomeComponent from './components/HomeComponent';
import OnboardWithUs from './components/user/OnboardWithUs';
import SetPassword from './components/user/SetPassword';
import Login from './components/user/Login';
import UploadInvoices from './components/invoiceManagement/UploadInvoices';
import InvoiceList from './components/invoiceManagement/InvoiceList';
import InvoiceIntegrity from './components/invoiceManagement/InvoiceIntegrity';

const routes = [
  {
    path: "/",
    element: <HomeComponent />,
  },
  {
    path: "/recruit-with-us",
    element: <OnboardWithUs />,
  },
  {
    path: "/setPassword",
    element: <SetPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/upload-invoice",
    element: <UploadInvoices />,
  },
  {
    path: "/invoice-list",
    element: <InvoiceList />,
  },
  {
    path: "/invoice-integrity",
    element: <InvoiceIntegrity />,
  }
];

function App() {
  const element = useRoutes (routes);
  return <>{element}</>;
}

export default App;
