// const env = "local"
const env = "dev"
// const env = "prod"

function get_base_url() {
    if (env === "local") {
        return "http://localhost:3636"
    }
    else if (env === "dev") {
        return "https://dev.server.cyberx.tradereboot.com"
    }
    else {
        return ""
    }
}

function getPlatFormUrl() {
    if (env === "dev" || env === "local") {
        return "https://app.tradereboot.com"
    }
    if (env === "prod") {
        return "https://app.tradereboot.com"
    }
}

function getPlatFormBackendUrl() {
    if (env === "dev" || env === "local") {
        return "https://devserver.tradereboot.com"
    }
    if (env === "prod") {
        return "https://server.tradereboot.com"
    }
}

function getWebsiteBackendURL() {
    if (env === "dev" || env === "local") {
        return "https://backendwebdev.sofodev.co"
    }
    if (env === "prod") {
        return "https://backend.tradereboot.com"
    }
}

export const platformURL = getPlatFormUrl()
export const platformBackendURL = getPlatFormBackendUrl()
export const websiteBackendURL = getWebsiteBackendURL()

export const BASE_URL = get_base_url()
