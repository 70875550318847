import axios from 'axios'
import { BASE_URL, platformBackendURL } from './config'
import toastDisplay from './ToastNotification'
import cryptoJs from "crypto-js";
import jsPDF from 'jspdf';

export const secretKey = "5N-LzDy,gcKYHYge*d]2Q59,}GR='V&=3/Me;u;iYw]92<7cX@uEmE):+%|/#Am"

export const status = [200, 403, 409]

export const companyDesignation = [
    { name: 'Chief Executive Officer' },
    { name: 'Chief Operating Officer' },
    { name: 'Chief Financial Officer' },
    { name: 'Chief Technology Officer' },
    { name: 'Chief Marketing Officer' },
    { name: 'Chief Legal Officer' },
    { name: 'Company Director' },
    { name: 'Product Manager' },
    { name: 'Other' }
]

export const apicall = (url, req, formData) => {
    let headers = {}
    let userData = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"))

    if (userData && userData.token) {
        headers["Authorization"] = "Bearer " + userData.token
        if(formData){
            req.append("userId", userData.userId)
        }
        else{
            req["userId"] = userData.userId
        }
    }

    // console.log("apicall", BASE_URL + url, req, headers);

    return new Promise(resolve => {
        axios.post(BASE_URL + url, req, { headers }).then(result => {
            console.log("apicall in then", result);
            resolve({ status: result.status, data: result.data.data ? result.data.data : result.data, msg: result.data.msg || undefined })
        }).
            catch(error => {
                console.log("apicall in catch", error);
                if (status.indexOf(error.response.status) == -1) {
                    if (error.response.status === 401) {
                        localStorage.clear()
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 3000);
                        toastDisplay("Your session has expired, please login again to continue using", "info")
                    }
                    else {
                        toastDisplay("Unable to process your request at the moment, kindly try again after sometime", "info")
                    }
                }
                resolve({ status: error.response.status, msg: error.response?.data?.msg || undefined });
            })
    })
}

export const encryptStr = str => {
    return encodeURIComponent(cryptoJs.AES.encrypt(str, secretKey).toString()).toString()
}

export const decryptStr = encStr => {
    // console.log('11111111111111111111111111111', encStr);
    encStr = decodeURIComponent(encStr)
    // console.log("22222222222222222222222222222", encStr);
    let bytes = cryptoJs.AES.decrypt(encStr, secretKey)
    return bytes.toString(cryptoJs.enc.Utf8)
}

const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

const imageDimensionsOnA4 = (dimensions) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;
    // If the image is in landscape, the full width of A4 is used.
    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
        };
    }
    // If the image is in portrait and the full height of A4 would skew
    // the image ratio, we scale the image dimensions.
    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * dimensions.height) / dimensions.width;
        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;
        return {
            height: scaledImageHeight,
            width: scaledImageHeight * imageRatio,
        };
    }
    // The full height of A4 can be used without skewing the image ratio.
    return {
        width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
        height: A4_PAPER_DIMENSIONS.height,
    };
};


export async function convertImageToPdf(imgDataUrl, imgFileName) {
    return new Promise((resolve, reject) => {
        try {
            let pdfFileName = imgFileName.split(".")[0] + ".pdf"
            let image = new Image();
            image.src = imgDataUrl;
            image.onload = function () {
                const doc = new jsPDF();
                doc.deletePage(1);
                const imageDimensions = imageDimensionsOnA4({
                    width: image.width,
                    height: image.height,
                });
                doc.addPage();
                doc.addImage(
                    image.src,
                    // Images are vertically and horizontally centered on the page.
                    (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                    (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                    imageDimensions.width,
                    imageDimensions.height,
                    "",
                    "FAST"
                );
                // To view pdf in new tab
                // const test = doc.output('bloburl')
                // window.open(test, "_blank");
                const pdfBlob = doc.output('blob');
                resolve(
                    {
                        "file": new File([pdfBlob], pdfFileName, { type: pdfBlob["type"], lastModified: new Date() }),
                        "dataUrl": doc.output('datauristring', { filename: pdfFileName })
                    }
                );
            };
        }
        catch (error) {
            reject(false)
        }
    })
}