import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const toastDisplay = function (data, type, callback) {

  if (typeof data === "object") {
    data = data.message
  }

  if (type === "success") {
    toast.success("✔️  " + data, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "rounded-lg"
    });
  }
  else if (type === "error") {
    toast.error("✘  " + data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "rounded-lg"
    });
  }
  else if (type === "info") {
    toast.info("🔔  " + data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "rounded-lg"
    });
  }
  else if (type === "warn") {
    toast.warn("⚠️  " + data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "rounded-lg"
    });
  }
  if(callback){
    setTimeout(() => {
      callback()
    }, 1500);
  }
}

export default toastDisplay;
