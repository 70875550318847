import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { platformBackendURL } from "../../constants/config";
import { apicall, convertImageToPdf } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import Header from "../header";
import SideBar from "../sideBar";
import Papa from "papaparse";
import moment from "moment";
import { SearchComponent } from "../../constants/searchComponent";
import Pagination from "../../constants/pagination";

let columns = [{ name: "Invoice Number", alt: "invoiceNo" }, { name: "Invoice Currency", alt: "invoiceCurrency" },
{ name: "Invoice Amount", alt: "invoiceAmount" }, { name: "PO Number", alt: "poNo" }, { name: "BL Number", alt: 'blNo' },
{ name: "BL Issue Date", alt: "blIssueDate", conv: (d) => {return moment(d).format("YYYY-MM-DD")} },
{ name: "Exporter Name", alt: "exporterName" }, { name: "Buyer Name", alt: "buyerName" }, { name: "Shipment Origin Country", alt: "shipmentOriginCountry" },
{ name: "Shipment Origin City", alt: "shipmentOriginCity" }, { name: "Shipment Origin Postal Code", alt: "shipmentOriginPostalCode" }, { name: "Shipment Origin Address", alt: "shipmentOriginAddress" },
{ name: "Shipment Destination Country", alt: "shipmentDestinationCountry" }, { name: "Shipment Destination City", alt: "shipmentDestinationCity" },
{ name: "Shipment Destination Postal Code", alt: "shipmentDestinationPostalCode" }, { name: "Shipment Destination Address", alt: "shipmentDestinationAddress" },
{ name: "Disbursement schedule ", dynamic: true }
]

const InvoiceIntegrity = ({ activeComponent }) => {

    const [data, setData] = useState({ invoiceCurrency: "USD" })
    const [errors, setErrors] = useState({})
    const [showLoader, setShowLoader] = useState(false)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [totalCount, setTotalCount] = useState(0)
    const [refresh, setRefresh] = useState(1)
    const [countryData, setcountryData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [dbData, setDbData] = useState([])
    const [isApiHit, toggleIsApiHit] = useState(false)

    useEffect(() => {
        if (data?.invoiceDocument?.name) {
            analyzeInvoiceDocument()
        }
    }, [data.invoiceDocument])

    async function analyzeInvoiceDocument() {
        let formData = new FormData()
        formData.append("document", data.invoiceDocument)
        setShowLoader(true)
        toastDisplay("Analyzing document kindly wait", "info")
        try {
            let resp = await apicall('/invoice/analyzeInvoice', formData, true)
            // console.log("rrrrrrrrrrrrrrrrrrrrrrrrrr", resp);
            setData({
                ...data,
                invoiceNo: resp?.data?.invoice_number || "",

                exporterName: resp?.data?.supplier_name || "",
                shipmentOriginAddress: resp?.data?.supplier_address || "",
                shipmentOriginCity: resp?.data?.supplier_address_components?.city || "",
                shipmentOriginPostalCode: resp?.data?.supplier_address_components?.postalCode || "",
                shipmentOriginCountry: resp?.data?.supplier_address_components?.countryName || "",

                buyerName: resp?.data?.customer_name || "",
                shipmentDestinationAddress: resp?.data?.customer_address || "",
                shipmentDestinationCity: resp?.data?.customer_address_components?.city || "",
                shipmentDestinationPostalCode: resp?.data?.customer_address_components?.postalCode || "",
                shipmentDestinationCountry: resp?.data?.customer_address_components?.countryName || "",
            })
        } catch (error) {
        }
        setShowLoader(false)
    }

    useEffect(() => {
        setShowLoader(true)
        axios.get(platformBackendURL + "/getcurrencylist").then((result) => {
            setShowLoader(false)
            if (result.data.message.length) {
                setCurrencyData(result.data.message);
            }
        }).catch((e) => {
            console.log("error in getcurrencylist", e);
            setShowLoader(false)
        });

        axios.get(platformBackendURL + "/getallCountry").then((result) => {
            setShowLoader(false)
            if (result.data.message.length) {
                setcountryData(result.data.message);
            }
        }).catch((e) => {
            console.log("error in getallCountry", e);
            setShowLoader(false)
        });
    }, [])

    const handleFiles = (event) => {
        event.persist();
        let file_type = event.target.files[0]["type"].toLowerCase()
        if (!((file_type.includes("pdf")) || (file_type.includes("png")) || (file_type.includes("jpeg")))) {
            setErrors({ ...errors, invoiceDocument: "Files with pdf, png & jpeg extension are allowed" })
            return
        }
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = async (e) => {
            let fileObj = event.target.files[0]
            let fileDataUrl = e.target.result
            if (!file_type.includes("pdf")) {
                let response = await convertImageToPdf(fileDataUrl, event.target.files[0]["name"]);
                fileObj = response["file"]
                fileDataUrl = response['dataUrl']
            }
            setData({ ...data, [event.target.name]: fileObj })
            setErrors({ ...errors, [event.target.name]: "" });
        }
    };

    const handleChange = e => {
        e.persist()
        setData({ ...data, [e.target.name]: e.target.value })
        setErrors({ ...errors, [e.target.name]: "" })
    }

    async function handleValidation(e) {
        e.preventDefault()
        let validate = ["invoiceNo", "invoiceCurrency", "invoiceAmount", "poNo", "blNo", "blIssueDate", "exporterName", "buyerName", "shipmentOriginCountry", "shipmentDestinationCountry", "shipmentOriginCity",
            "shipmentDestinationCity"]
        let err = {}
        for (let index = 0; index < validate.length; index++) {
            const element = validate[index];
            if (!data[element]) {
                err[element] = "Mandatory field"
            }
        }
        if (!Object.keys(err).length) {
            setShowLoader(true)
            let formData = new FormData()
            // if (data.blNo) {
                formData.append('blNo', data.blNo)
                formData.append('blIssueDate', data.blIssueDate)
            // }
            formData.append('buyerName', data.buyerName)
            formData.append('exporterName', data.exporterName)
            formData.append('invoiceAmount', data.invoiceAmount)
            formData.append('invoiceCurrency', data.invoiceCurrency)
            if (data?.invoiceDocument?.name) {
                formData.append('invoiceDocument', data.invoiceDocument)
            }
            formData.append('invoiceNo', data.invoiceNo)
            formData.append('poNo', data.poNo)
            formData.append('shipmentDestinationCity', data.shipmentDestinationCity)
            formData.append('shipmentDestinationCountry', data.shipmentDestinationCountry)
            formData.append('shipmentOriginCity', data.shipmentOriginCity)
            formData.append('shipmentOriginCountry', data.shipmentOriginCountry)
            let resp = await apicall('/fuzzyController/check-invoice', formData, true)
            console.log("check-invoice====================>", resp);
            if (resp.status / 1 == 200) {
                setDbData(resp.data)
                toggleIsApiHit(true)
            }
            setShowLoader(false)
        }
        setErrors(err)
    }

    return (
        <>
            {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <div class="container-fluid">
                <div class="row flex-nowrap">
                    <SideBar state={"invoiceIntegrity"}  />
                    <div class="col">
                        <Header title={"Check Invoice Integrity"} />
                        <div className="row bg-white m-1 p-3">
                            <div className="row mb-3">
                                <div class="col-5">
                                    <label for="invoiceDocument" class="form-label">Upload Invoice</label>
                                    <input type="file" class="form-control" id="invoiceDocument" aria-describedby="invoiceDocument"
                                        name="invoiceDocument"
                                        // value={data.invoiceDocument}
                                        onChange={handleFiles} />
                                    {/* <div id="emailHelp" class="form-text">Upload invoice document to fetch details automatically.</div> */}
                                    {errors.invoiceDocument ? (
                                        <div id="invoiceDocument" class="form-text text-danger">{errors.invoiceDocument}</div>) : null}
                                </div>
                                <div
                                    // style={{paddingTop: '2.3rem', height: '5.3rem'}}
                                    class="col-5 align-self-center pt-4">
                                    <div id="emailHelp" class="form-text">Upload invoice document to fetch details automatically.</div>
                                </div>
                            </div>

                            <div className="col-5">
                                <div class="mb-3">
                                    <label for="invoiceNo" class="form-label">Invoice Number *</label>
                                    <input type="text" class="form-control" id="invoiceNo" aria-describedby="invoiceNo"
                                        name="invoiceNo" value={data.invoiceNo}
                                        onChange={handleChange} />
                                    {errors.invoiceNo ? (
                                        <div id="invoiceNo" class="form-text text-danger">{errors.invoiceNo}</div>) : null}
                                </div>

                                <div class="mb-3">
                                    <label for="poNo" class="form-label">PO Number *</label>
                                    <input type="text" class="form-control" id="poNo" aria-describedby="poNo"
                                        name="poNo" value={data.poNo}
                                        onChange={handleChange} />
                                    {errors.poNo ? (
                                        <div id="poNo" class="form-text text-danger">{errors.poNo}</div>) : null}
                                </div>

                                <div class="mb-3">
                                    <label for="blIssueDate" class="form-label">BL Issue Date *</label>
                                    <input type="date" class="form-control" id="blIssueDate" aria-describedby="blIssueDate"
                                        name="blIssueDate" value={data.blIssueDate}
                                        onChange={handleChange} />
                                    {errors.blIssueDate ? (
                                        <div id="blIssueDate" class="form-text text-danger">{errors.blIssueDate}</div>) : null}
                                </div>

                                <div class="mb-3">
                                    <label for="buyerName" class="form-label">Buyer Name *</label>
                                    <input type="text" class="form-control" id="buyerName" aria-describedby="buyerName"
                                        name="buyerName" value={data.buyerName}
                                        onChange={handleChange} />
                                    {errors.buyerName ? (
                                        <div id="buyerName" class="form-text text-danger">{errors.buyerName}</div>) : null}
                                </div>


                                <div class="mb-3">
                                    <label for="shipmentOriginCity" class="form-label">Shipment Origin City *</label>
                                    <input type="text" class="form-control" id="shipmentOriginCity" aria-describedby="shipmentOriginCity"
                                        name="shipmentOriginCity" value={data.shipmentOriginCity}
                                        onChange={handleChange} />
                                    {errors.shipmentOriginCity ? (
                                        <div id="shipmentOriginCity" class="form-text text-danger">{errors.shipmentOriginCity}</div>) : null}
                                </div>  

                                <div class="mb-3">
                                    <label for="shipmentDestinationCity" class="form-label">Shipment Destination City *</label>
                                    <input type="text" class="form-control" id="shipmentDestinationCity" aria-describedby="shipmentDestinationCity"
                                        name="shipmentDestinationCity" value={data.shipmentDestinationCity}
                                        onChange={handleChange} />
                                    {errors.shipmentDestinationCity ? (
                                        <div id="shipmentDestinationCity" class="form-text text-danger">{errors.shipmentDestinationCity}</div>) : null}
                                </div>                         

                                
                            </div>

                            <div className="col-5">
                                <div class="mb-3">
                                    <label for="" class="form-label">Invoice Amount *</label>
                                    <div className="d-flex">
                                        <div className="w-35">
                                            <select name={"invoiceCurrency"} className="form-select" id="invoiceCurrency" aria-label="Floating label select example"
                                                value={data.invoiceCurrency} onChange={handleChange}
                                            >
                                                <option value={""} selected>Select</option>
                                                {currencyData.map((item, index) => {
                                                    return (
                                                        <option value={item.code}>{item.code}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="w-65">
                                            <input type="number" class="form-control" id="invoiceAmount"
                                                name="invoiceAmount" value={data.invoiceAmount} onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {(errors.invoiceAmount || errors.invoiceCurrency) ? (
                                        <div id="country" class="form-text text-danger">{errors.invoiceAmount || errors.invoiceCurrency}</div>) : null}
                                </div>
                                <div class="mb-3">
                                    <label for="blNo" class="form-label">BL Number *</label>
                                    <input type="text" class="form-control" id="blNo" aria-describedby="blNo"
                                        name="blNo" value={data.blNo}
                                        onChange={handleChange} />
                                    {errors.blNo ? (
                                        <div id="blNo" class="form-text text-danger">{errors.blNo}</div>) : null}
                                </div>

                                <div class="mb-3">
                                    <label for="exporterName" class="form-label">Exporter Name *</label>
                                    <input type="text" class="form-control" id="exporterName" aria-describedby="exporterName"
                                        name="exporterName" value={data.exporterName}
                                        onChange={handleChange} />
                                    {errors.exporterName ? (
                                        <div id="exporterName" class="form-text text-danger">{errors.exporterName}</div>) : null}
                                </div>
                                

                                <div class="mb-3">
                                    <label for="shipmentOriginCountry" class="form-label">Shipment Origin Country *</label>
                                    <select name={"shipmentOriginCountry"} className="form-select" id="shipmentOriginCountry" aria-label="Floating label select example"
                                        value={data.shipmentOriginCountry} onChange={handleChange}
                                    >
                                        <option value={""} selected>Select Country</option>
                                        {countryData.map((item, index) => {
                                            return (
                                                <option value={item.name}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.shipmentOriginCountry ? (
                                        <div id="shipmentOriginCountry" class="form-text text-danger">{errors.shipmentOriginCountry}</div>) : null}
                                </div>                               

                                <div class="mb-3">
                                    <label for="shipmentDestinationCountry" class="form-label">Shipment Destination Country *</label>
                                    <select name={"shipmentDestinationCountry"} className="form-select" id="shipmentDestinationCountry" aria-label="Floating label select example"
                                        value={data.shipmentDestinationCountry} onChange={handleChange}
                                    >
                                        <option value={""} selected>Select Country</option>
                                        {countryData.map((item, index) => {
                                            return (
                                                <option value={item.name}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.shipmentDestinationCountry ? (
                                        <div id="shipmentDestinationCountry" class="form-text text-danger">{errors.shipmentDestinationCountry}</div>) : null}
                                </div>                             

                                

                            </div>

                            <div className="w-25 pt-4 pb-3">
                                <button onClick={handleValidation} type="submit" class="btn btn-primary w-100">Submit</button>
                            </div>

                            {isApiHit ? (
                                <div className="row">
                                    {!dbData.length ? (
                                        <label className="font-size-18 text-center font-wt-600 my-4">{`No Result Found`}</label>) : (
                                        <label className="font-size-18 text-center font-wt-600 my-4">{`Result Found With ${dbData[0]["matchPercentage"]}% Accuracy `}</label>
                                    )}
                                    {dbData.length ? columns.map((l, m) => {
                                        if (!l.dynamic) {
                                            return (
                                                <div className="w-25 my-2   ">
                                                    <div>
                                                        <label className="font-size-14 ">{ dbData?.[0]?.[l.alt] ? (l.conv ? l.conv(dbData?.[0]?.[l.alt]) : dbData?.[0]?.[l.alt]) : "NA"}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="font-size-12 font-wt-500 ">{l.name}</label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }) : null}

                                    {dbData?.[0]?.disbursements?.length ?
                                        dbData[0].disbursements.map((element, indx) => {
                                            return (
                                                <div className="w-25 my-2">
                                                    <div>
                                                        <label className="font-size-14 ">{`${element.disbursedDate} (${element.disbursedAmt} ${element.disbursedCurrency})`}</label>
                                                    </div>
                                                    <div>
                                                        <label className="font-size-12 font-wt-500 ">{`Disbursement Details - ${indx + 1}`}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default InvoiceIntegrity;