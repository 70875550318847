import React from "react"

export const SearchComponent = ({ search, setSearch, refresh, setRefresh }) => {

    const handleKeyPress = (event) => {
        event.persist();
        if (event.keyCode === 13) {
            setRefresh(refresh + 1)
        }
    };

    const handleChange = e => {
        e.persist()
        setSearch(e.target.value)
    }

    return (
        <div className="d-flex justify-content-end">
            <div className="">
                <div class="input-group">
                    <input onChange={handleChange}
                        onKeyDown={handleKeyPress} name={"search"} value={search}
                        type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                    <button
                        onClick={() => setRefresh(refresh + 1)}
                        type="button" class="btn btn-outline-primary">search</button>
                </div>
            </div>
        </div>
    )
}