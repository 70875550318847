import React, { useState } from "react";

const SideBar = ({ activeComponent, state, url }) => {

    let isInvoiceOpen = (url==="upload-invoice" || url==="invoice-list")
    const [openTab, setOpenTab] = useState({invoice: isInvoiceOpen})

    async function logoutUser() {
        localStorage.clear()
        window.location = "/"
    }

    return (

        <div class="col-auto col-md-3 col-xl-2-5 px-sm-1 px-0 bg-white d-flex justify-content-center min-vh-100">
            <div
            style={{
               width: "-webkit-fill-available"
            }}
            class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 position-fixed">
                <a href="/" class="d-flex align-items-center align-self-center mt-4 mb-3 text-white text-decoration-none">
                    <img className="brand-logo cursor" src="assets/images/tr_logo.png" />
                </a>
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">

                    <li className={(state === "invoice" ? "bg-e7f1ff" : "bg-light") + (" nav-item nav-item-css")}
                    >
                        <a
                            onClick={() => setOpenTab({["invoice"]: !openTab.invoice })}
                            href="#submenu1" data-bs-toggle="collapse" class="nav-link px-0 align-middle text-center">
                            <i class="fs-4 bi-speedometer2"></i>
                            <img src="assets/images/invoice_menu.svg" className="mr-3"

                            />
                            <span className={"d-none d-sm-inline font-size-12 text-dark font-wt-500"}>
                                Invoice Management
                                <img src="assets/images/arrow.png" className="ml-4 sideMenuIcon"
                                    style={openTab?.["invoice"] ? {
                                        transform: "rotate(180deg)"
                                    } : {}}
                                />
                            </span>
                        </a>
                        <ul class={`collapse ${isInvoiceOpen && ' show '} nav flex-column`}id="submenu1" data-bs-parent="#menu">
                            <li className={`w-100 text-center ${url === 'upload-invoice' ? ' subnav-item-css' : ""}`}>
                                <a href="upload-invoice" class="nav-link px-0"> <span class={`d-none d-sm-inline font-size-12 text-dark font-wt-400 mr-3`}>Upload Invoice</span>
                                </a>
                            </li>
                            <li className={`text-center ${url === 'invoice-list' ? ' subnav-item-css' : ""}`}>
                                <a href="invoice-list" class="nav-link px-0"> <span class="d-none d-sm-inline font-size-12 text-dark font-wt-400 text-center">Invoice List</span>  </a>
                            </li>
                        </ul>
                    </li>

                    <li className={(state === "invoiceIntegrity" ? "bg-e7f1ff" : "bg-light") + (" nav-item nav-item-css")}
                    >
                        <a
                            // onClick={() => window.location = "/invoice-integrity"}
                            href="invoice-integrity" data-bs-toggle="" class="nav-link px-0 align-middle text-center">
                            <i class="fs-4 bi-speedometer2"></i>
                            <img src="assets/images/invoice_menu.svg" className="mr-3"
                            />
                            <span className={"d-none d-sm-inline font-size-12 text-dark font-wt-500"}>
                                Check Invoice Integrity
                            </span>

                        </a>
                    </li>
                </ul>
                <hr />
                <div class="dropdown pb-4">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="assets/images/user_avatar.png" alt="hugenerd" width="30" height="30" class="rounded-circle" />
                        <span class="d-none d-sm-inline mx-1 text-dark font-size-12 font-wt-500">{
                            JSON.parse(localStorage.getItem("userData") || "{}")?.otherData?.lenderName || "NA"}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                        <li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li>
                        <li>
                            <hr class="dropdown-divider" />
                        </li>
                        <li
                        ><label
                            onClick={logoutUser}
                            class="dropdown-item">Sign Out</label></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideBar;