import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { platformBackendURL } from "../../constants/config";
import { apicall, convertImageToPdf } from "../../constants/myFunctions";
import toastDisplay from "../../constants/ToastNotification";
import Header from "../header";
import SideBar from "../sideBar";
import Papa from "papaparse";
import moment from "moment";
import { SearchComponent } from "../../constants/searchComponent";
import Pagination from "../../constants/pagination";

const InvoiceList = ({ activeComponent }) => {

    let columns = [{ name: "Invoice Number", alt: "invoiceNo" }, { name: "Invoice Currency", alt: "invoiceCurrency" },
    { name: "Invoice Amount", alt: "invoiceAmount" }, { name: "PO Number", alt: "poNo" }, { name: "BL Number", alt: 'blNo' },
    { name: "BL Issue Date", alt: "blIssueDate", conv: (d) => {return moment(d).format("YYYY-MM-DD")} },
    { name: "Exporter Name", alt: "exporterName" }, { name: "Buyer Name", alt: "buyerName" }, { name: "Shipment Origin Country", alt: "shipmentOriginCountry" },
    { name: "Shipment Origin City", alt: "shipmentOriginCity" }, { name: "Shipment Origin Postal Code", alt: "shipmentOriginPostalCode" }, { name: "Shipment Origin Address", alt: "shipmentOriginAddress" },
    { name: "Shipment Destination Country", alt: "shipmentDestinationCountry" }, { name: "Shipment Destination City", alt: "shipmentDestinationCity" },
    { name: "Shipment Destination Postal Code", alt: "shipmentDestinationPostalCode" }, { name: "Shipment Destination Address", alt: "shipmentDestinationAddress" },
    { name: "Disbursement schedule ", dynamic: true }
    ]

    const [dbData, setDbData] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [totalCount, setTotalCount] = useState(0)
    const [refresh, setRefresh] = useState(1)

    useEffect(() => {
        setShowLoader(true)
        apicall('/invoice/getInvoiceDetails', { search, page }).then(res => {
            setShowLoader(false)
            if (res?.data?.data?.length) {
                setDbData(res.data.data)
                setTotalCount(res.data.totalCount)
            }
            else{
                setDbData([])
                setTotalCount(0)
            }
        })
    }, [page, refresh])

    return (<>
        {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
        <div class="container-fluid">
            <div class="row flex-nowrap">
                <SideBar state={"invoice"} url={'invoice-list'} />
                <div class="col">
                    <Header title={"Invoice List"} />
                    <SearchComponent search={search} setSearch={setSearch} refresh={refresh} setRefresh={setRefresh} />
                    <div class="accordion my-4" id="accordionForBulk">
                        {dbData.map((i, j) => {
                            let disbursementArr = i["disbursements"] || []
                            return (
                                <div class="accordion-item">
                                    <h2 class="accordion-header position-relative" id={`heading${j}`}>
                                        <button
                                            // style={{ paddingLeft: '3rem' }}
                                            className={`accordion-button collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${j}`} aria-expanded="false" aria-controls={`collapse${j}`}>
                                            <label className={` ${' text-dark '} font-wt-600 font-size-14`}>{`${i['invoiceNo']} | ${i["poNo"]} | ${i["invoiceAmount"]} ${i["invoiceCurrency"]} | ${i["exporterName"]} | ${i["buyerName"]} | ${i["shipmentOriginCountry"]} | ${i["shipmentDestinationCountry"]} `}</label>
                                        </button>

                                    </h2>
                                    <div id={`collapse${j}`} class="accordion-collapse collapse" aria-labelledby={`heading${j}`} data-bs-parent="#accordionForBulk">
                                        <div class="accordion-body">
                                            <div className="row d-flex">
                                                {columns.map((l, m) => {
                                                    if (!l.dynamic) {
                                                        return (
                                                            <div className="w-25 my-2   ">
                                                                <div>
                                                                    <label className="font-size-14 ">{ i[l.alt] ? (l.conv ? l.conv(i[l.alt]) : i[l.alt]) : "NA"}</label>
                                                                </div>
                                                                <div>
                                                                    <label className="font-size-12 font-wt-500 ">{l.name}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                <>
                                                    {
                                                        disbursementArr.map((element, indx) => {
                                                            // console.log("eeeeeeeeeeeeeeeeeeeeeeeee", element, indx);
                                                            return (
                                                                <div className="w-25 my-2">
                                                                    <div>
                                                                        <label className="font-size-14 ">{`${element.disbursedDate} (${element.disbursedAmt} ${element.disbursedCurrency})`}</label>
                                                                    </div>
                                                                    <div>
                                                                        <label className="font-size-12 font-wt-500 ">{`Disbursement Details - ${indx + 1}`}</label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Pagination page={page} totalCount={totalCount} perPage={10} onPageChange={(currPage) => setPage(currPage)}/>
                </div>
            </div>
        </div>
    </>)
}

export default InvoiceList;