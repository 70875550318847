import React from "react";

const ModalPopup = ({ show, onClose, btns, title, body }) => {
    return (
        <div class={`modal ${show ? ' d-block ' : ' d-none '}`} tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-size-18">{title}</h5>
                        <button onClick={onClose} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {/* <p>Modal body text goes here.</p> */}
                        {body}
                    </div>
                    <div class="modal-footer">
                        {btns?.length ? btns.map(i => {
                            return (
                                <button onClick={() => i.onClick()} type="button" class={i.class}>{i.name}</button>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPopup